import React, { useState } from "react";
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Card,
  CardContent,
} from "@mui/material";

const LandingPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    needs: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const generateSMSLink = () => {
    const { name, email, needs } = formData;
    return `sms:+16024817070?body=Hi, my name is ${encodeURIComponent(
      name
    )}. My email is ${encodeURIComponent(email)}, and I need: ${encodeURIComponent(
      needs
    )}`;
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 4, mb: 4 }}>
      {/* Header */}
      <Box
        sx={{
          textAlign: "center",
          backgroundColor: "#007bff",
          color: "#ffffff",
          padding: "20px",
          borderRadius: "8px",
        }}
        mb={4}
      >
        <Typography variant="h4" gutterBottom>
          Your Fractional CTO—Over Coffee!
        </Typography>
        <Typography variant="subtitle1">
          Empower Your Business with Expert IT Leadership, Without the Full-Time Commitment.
        </Typography>
      </Box>

      {/* About Section */}
      <Card sx={{ mb: 4 }}>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            Welcome to Your Next Big Idea
          </Typography>
          <Typography variant="body1" color="text.secondary">
            With over 30 years of IT and healthcare technology experience, I
            help businesses like yours achieve their goals with tailored
            solutions. Whether you need technical guidance, project direction,
            or a tech-savvy partner to strategize your next move, I’m here for
            you—over coffee or beyond.
          </Typography>
        </CardContent>
      </Card>

      {/* Contact Form */}
      <Card>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            Let’s Connect!
          </Typography>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            Fill out the form below, and I’ll get back to you promptly.
          </Typography>
          <Box component="form" noValidate sx={{ mt: 2 }}>
            <TextField
              fullWidth
              label="Your Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              margin="normal"
              required
            />
            <TextField
              fullWidth
              label="Your Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              margin="normal"
              required
            />
            <TextField
              fullWidth
              label="Tell Me About Your Needs"
              name="needs"
              multiline
              rows={4}
              value={formData.needs}
              onChange={handleChange}
              margin="normal"
              required
            />
            <Button
              href={generateSMSLink()}
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 2 }}
            >
              Send via SMS
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default LandingPage;
